<template>
    <v-app>    
        <v-main>
            <v-container class="fill-height container-background" fluid></v-container>
            <v-container 
                class="fill-height justify-center" 
                fluid
            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto mx-auto"
                >
                    <v-col >
                        <v-expand-transition>
                            <v-card 
                                class="elevation-12 pa-4 mx-auto"
                                v-show="expand"
                                max-width="350"
                            >
                                <v-row
                                    justify="center"
                                >     
                                    <v-img
                                        src="../../assets/logo.png"
                                        max-width="120"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-row>
                                <v-row no-gutters>                                    

                                    <v-col cols="12">
                                        <div 
                                            class="pa-4"
                                            v-if="getloginErrors"
                                        >
                                            <v-alert                                                    
                                                type="error"
                                                outlined                                                    
                                                
                                                class="red--text"
                                            >
                                                <div>{{ getloginErrors }}</div>
                                            </v-alert>    
                                        </div>
                                        <div class="text-center font-weight-light primary--text">Welcome to</div>
                                        <div 
                                            class="text-h4 text-sm-h5 font-weight-black text-center primary--text"
                                            v-html="schoolName"
                                        >                                           
                                        </div> 
                                        <v-window v-model="step">
                                            <v-window-item 
                                                :value="1"
                                                transition="slide-x-transition"
                                            >   
                                                <slot name="username"></slot>
                                            </v-window-item>

                                            <v-window-item
                                                :value="2"
                                                transition="slide-x-transition"
                                            >
                                                                                     
                                                <v-card-text>
                                                    <div class='primary--text text-h5 text-center '>Enter Password</div>                                            
                                                </v-card-text>
                                                <slot name="password"></slot>
                                            </v-window-item>

                                            <v-window-item :value="3">

                                                <div class="pa-4">
                                                    <v-alert                                                    
                                                        type="error"
                                                        outlined                                                    
                                                        v-if="resetPasswordErrors"
                                                        class="red--text"
                                                    >
                                                        
                                                    </v-alert>    
                                                </div>

                                                <v-card-text>
                                                    <div class="primary--text text-h5 text-left">Reset Password</div>                                            
                                                </v-card-text>                                            

                                                <v-form 
                                                    class="pa-5"
                                                    @submit.prevent
                                                >
                                                    <v-text-field                                                            
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        label="New Password"                                                            
                                                        @click:append="show = !show"                                                    
                                                        name="new-password"
                                                        v-model="formData.newPassword"                                                            
                                                        autofocus
                                                    >
                                                        <v-icon
                                                            slot="prepend"
                                                            color="primary"
                                                        >
                                                            mdi-key
                                                        </v-icon>
                                                    </v-text-field>

                                                    <v-text-field                                                            
                                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :type="show ? 'text' : 'password'"
                                                        label="Confirm Password"                                                            
                                                        @click:append="show = !show"                                                    
                                                        name="confirm-password"
                                                        v-model="formData.confirmPassword"                                                                                                                       
                                                    >
                                                        <v-icon
                                                            slot="prepend"
                                                            color="primary"
                                                        >
                                                            mdi-key
                                                        </v-icon>
                                                    </v-text-field>
                                                
                                            
                                                    <div class="text-right mt-3 mb-4">                                                
                                                        <v-btn 
                                                            color="primary" 
                                                            @click="changePassword" 
                                                            dark
                                                            block
                                                        >
                                                            CHANGE PASSWORD
                                                        </v-btn>
                                                    </div>
                                                </v-form>

                                            </v-window-item>

                                        </v-window>
                                    </v-col>

                                </v-row>
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="overlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-container>               
        </v-main>
        
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'Login',
    data: () => ({
        //step: 1,
        resetPasswordErrors: false,
        resetPassword: false,
        show: false,
        formData: {
            newPassword: '',
            confirmPassword: '',
        },        
    }),
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'termReports/getSchoolName',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            getloginErrors: 'auth/getLoginErrors',
            getAuthenticated: 'auth/getAuthenticated',
            getUser: 'auth/getUser',
            step: 'auth/getStep',
            overlay: 'auth/getOverlay',
            expand: 'auth/getExpand',            
        }),
        getThemeColor(){          
          return 'primary';
        },
        signInClass(){
            return 'primary d-flex pt-4 flex-column justify-center align-center';
        },
       
        icon_color(){
             return 'primary';
        },
        
    },
    methods: {
        ...mapActions({
            postNewPassword: 'auth/changePassword',
            employee: 'auth/employee',
        }),
        ...mapMutations({
            setNewPassword: 'auth/setNewPassword',
            setAuthenticated: 'auth/setAuthenticated',
            setOverlay: 'auth/setOverlay',
            setExpand: 'auth/setExpand', 
        }),
        async changePassword(){
            //console.log(this.formData);
            if(this.formData.newPassword && this.formData.confirmPassword){
                if(this.formData.newPassword == this.formData.confirmPassword){
                    console.log('changing password...');
                    this.setNewPassword(this.formData.confirmPassword);
                    try {
                        let response = await this.postNewPassword();
                        if(response.status == 200){
                            this.setAuthenticated(true);
                            this.setExpand(false);
                            await this.employee();
                            setTimeout(() => {this.setOverlay(false)})  
                            this.$router.replace('/term-reports');
                        }
                        console.log(response);
                    } catch (error) {
                        console.log(error);
                    }
                }
                else{
                    console.log('passwords dont match')
                }
            }
            else{
                console.log('passwords blank');
            }
        }    
    }
}
</script>

<style scoped>
    .container-background{
        /* background-image: url('../../assets/background.jpg'); */
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
        /* background: rgb(2,0,36);
        background: linear-gradient(90deg, #4B79A1 , #283E51 ) ; */
    }
       
</style>