<template>
    <v-app>
        <v-app-bar 
            app
            color="secondary"
            dark
            clipped-left
            elevation="0"
            dense
        > 
            <div class="d-flex align-center primary--text text-uppercase font-weight-bold">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="../assets/logo.png"
                    transition="scale-transition"
                    width="35"
                />
                {{ schoolName }}
                
            </div>
            <v-spacer></v-spacer>
            <v-tooltip 
                bottom
                color="primary"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar
                        color="primary"
                        class="mr-6"
                        size="36"
                        v-bind="attrs"
                        v-on="on"                  
                    >
                        <span class="secondary--text button">{{ initials }}</span>
                    </v-avatar>
                </template>
                {{ user }}     
            </v-tooltip>
            <Logout/>
        </v-app-bar>

        <v-main class="grey lighten-5">
            <v-container
                fluid                
            >
                <v-row 
                    v-if="welcomeMessage" 
                    class="mt-2 ml-4 mb-6"
                    justify="space-between"
                >
                    <div class="text-body-1 primary--text">
                        Welcome, 
                        <span class="font-weight-light font-italic text-h5">
                            {{ user }}
                        </span>
                    </div>                                             
                    <div class="text-body-1 primary--text mr-4" >
                        Term {{ currentTerm }}, {{ academicYear }} 
                    </div>
                </v-row>

                <v-row>
                    <v-col cols="1">
                        <v-card
                            width="70"
                            flat
                            class="d-flex flex-column align-center grey lighten-5"
                            v-if="mainMenuButton"
                        >
                            <v-expand-transition>
                                <v-btn
                                    color="primary"
                                    dark
                                    fab
                                    small
                                    @click="showMainMenu"    
                                >
                                    <v-icon>
                                        mdi-arrow-left
                                    </v-icon>
                                    
                                </v-btn>
                            </v-expand-transition>
                            <div
                                class="caption mt-2"
                            >
                                Main Menu
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="11" class="pt-0">
                        <main-menu v-if="mainMenu"></main-menu>
                        <enter-marks v-if="enterMarks"></enter-marks>
                        <edit-view-term-reports v-if="editViewTermReports"></edit-view-term-reports>
                    </v-col>
                </v-row>                                
            </v-container> 
        </v-main>        
        <sweet-modal
            :icon="modalIcon"
            ref="modal"
            overlay-theme="dark"
            :title="modalTitle"
            v-on:close="closeSweetModal"
        >
            {{ modalMessage }}
        </sweet-modal>
        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>
    </v-app>
</template>

<script>
import Logout from './auth/Logout';
import MainMenu from './MainMenu';
import EnterMarks from './EnterMarks';
import EditViewTermReports from './EditViewTermReports';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { SweetModal } from 'sweet-modal-vue';

export default {
    name: 'Admin',
    components: {
        Logout,        
        MainMenu,
        EnterMarks,
        EditViewTermReports,
        SweetModal,        
    },
    mounted(){ 
        this.initialize();        
    },
    watch: {
        modal:{
            handler(value){
                console.log('opening modal..')
                if(value) this.openModal();
            }
        },
    },
    
    computed: { 
        ...mapGetters({
            getUser: 'termReports/getUser',
            schoolName: 'termReports/getSchoolName',
            user: 'auth/getEmployeeSignedIn',
            initials: 'auth/getEmployeeInitials',
            enterMarks: 'termReports/getEnterMarks',
            editViewTermReports: 'termReports/getEditViewTermReports',
            mainMenu: 'termReports/getMainMenu',
            term: 'termReports/getCurrentTerm',
            year: 'termReports/getCurrentYear',
            modal: 'termReports/getModal',
            modalTitle: 'termReports/getModalTitle',
            modalIcon: 'termReports/getModalIcon',
            modalMessage: 'termReports/getModalMessage',
            welcomeMessage: 'termReports/getWelcomeMessage',
            overlay: 'termReports/getOverlay',
            mainMenuButton: 'termReports/getMainMenuButton',
            currentTerm: 'termReports/getCurrentTerm',
            academicYear: 'termReports/getAcademicYear', 
        }),      
              
    },

    methods: {
        ...mapMutations({
            setMainMenu: 'termReports/setMainMenu',
            setEnterMarks: 'termReports/setEnterMarks',
            setOverlay: 'termReports/setOverlay',
            setModal: 'termReports/setModal',
            setTable2Records: 'termReports/setTable2Records',
            expand: 'termReports/setExpandLessonOptions',
            setWelcomeMessage: 'termReports/setWelcomeMessage',
            setMainMenuButton: 'termReports/setMainMenuButton',
            setEditViewTermReports: 'termReports/setEditViewTermReports',
            setExpandEditViewTermReports: 'termReports/setEditViewTermReportsExpand',
        }),

        ...mapActions({
            currentPeriod: 'termReports/getCurrentAcademicPeriod',
            employee: 'auth/employee',
        }),

        async initialize(){
            console.log("initializing term reports..")
            this.setOverlay(true);
            try {
                const promiseCurrentPeriod = this.currentPeriod();
                const promiseEmployee = this.employee();
                await Promise.all([promiseCurrentPeriod, promiseEmployee]);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.setMainMenuButton(false);             
            this.setOverlay(false);
        },

        openModal(){            
            this.$refs.modal.open();
        },

        closeSweetModal () {
            this.setModal(false);
        },

        showMainMenu(){
            //console.log('closing..');            
            this.setTable2Records([]);
            this.setEnterMarks(false);
            this.setEditViewTermReports(false);
            // this.setOverlay(true);
            this.expand(false);
            this.setWelcomeMessage(true);
            this.setMainMenu(true);
            this.setMainMenuButton(false);
            this.setExpandEditViewTermReports(false);
        },
    }
}
</script>