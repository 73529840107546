<template>
    <v-expand-transition>
        <v-card
            class="mx-auto"
            width="400"            
            v-show="expand"
            light                        
        >    
            
            <v-toolbar
                color="primary white--text"
                light
            >
                <v-toolbar-title>Select Subject Class</v-toolbar-title>
            </v-toolbar>
            <v-card-text
                style="height: 60vh; overflow-y: auto"
            >
                <v-list 
                    light                
                >
                    <v-list-item-group v-model="lessonSelected">
                        <v-list-item
                            v-for="(item, i) in lessons"
                            :key="i"
                            @click="setLesson(item)"
                        >
                            <v-list-item-avatar>
                                <v-icon class="primary white--text">
                                    mdi-account-group
                                </v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.lesson"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card-text>
            <v-overlay
                :value="overlay"
                opacity="0.8"
                z-index="10"
                color="grey lighten-5"
                absolute                    
            >
                
                <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                ></v-progress-circular>                   
            </v-overlay>
        </v-card>
    </v-expand-transition>         
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {    
    mounted: function(){
        if(!this.admin)       
        this.getLessons();

    },

    data: () => ({
        lessons: [],
        lessonSelected: {lesson: '', className: '', subjectCode: '', subjectTitle: ''},
        open: true,
        clear: true,       
        focus: false,
        records: [],        
    }),
    
    computed: {
        ...mapGetters({            
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            id: 'auth/getEmployeeId',
            lessonRecords: 'termReports/getLessons',
            expand: 'termReports/getExpandLessonOptions',
            studentsTotal: 'termReports/getSubjectStudentsTotal',
            studentsRegistered: 'termReports/getSubjectStudentsRegistered',
            studentsMarksEntered: 'termReports/getSubjectStudentsMarksEntered',
            overlay: 'termReports/getOverlayLessons',
            admin: 'auth/getAdmin',                             
        }),       
    },

    methods: {
        ...mapActions({
           getLessonRecords: 'termReports/getLessons',
           getStudents: 'termReports/getStudents',           
        }),

        ...mapMutations({
            setLessonSelected: 'termReports/setLessonSelected',
            setExpand: 'termReports/setExpandLessonOptions',
            markSheetLoading: 'termReports/setMarkSheetLoading',
            markSheetOverlay: 'termReports/setMarkSheetOverlay',
            table2Records: 'termReports/setTable2Records',
            setOverlay: 'termReports/setOverlayLessons',
        }),
        
        async setLesson(lesson){
            // console.log(lesson);                               
            this.setLessonSelected(lesson);
            this.setExpand(false);
            //this.table2Records([]);
            setTimeout(() => {
                this.markSheetLoading(true);
            }, 700)            
            await this.getStudents(this.id);                      
        },
        async getLessons(){
            //console.log(this.records);
            let subject, class_id, form_level;
            // let lesson;
            await this.getLessonRecords(this.id);            
            this.markSheetLoading(false);
            this.setExpand(true);
            // console.log(this.lessonRecords);            
            this.lessonRecords.forEach(lessonRecord =>{
                subject = lessonRecord.subject.title;                
                form_level = lessonRecord.form_class.form_level;
                class_id = lessonRecord.form_class_id;
                //console.log(lessonRecord.form_class.form_level);
                this.lessons.push({
                    lesson: lessonRecord.form_class_id + ' ' + subject,
                    className: class_id,                    
                    subjectCode: lessonRecord.subject.id,
                    subjectTitle: subject,
                    formLevel: form_level,
                })                
                this.focus = true;
            });
            this.setOverlay(false);            
        },
    }
}
</script>