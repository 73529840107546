<template>
    <v-btn        
        text
        v-on:click="logOut"
      >
        <span :class="btnClass">Logout</span>
        <v-icon
            :color="color"
        >
            mdi-logout
        </v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    props: {
        color: {
            type: String,
            default: "primary"
        }        
    },

    computed: {
        btnClass () {
            return "mr-2 " + this.color + "--text";
        }
    },

    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'termReports/setOverlay',
            setStep: 'auth/setStep',
            setExpand: 'auth/setExpand',
            mainMenu: 'termReports/setMainMenu',
            enterMarks: 'termReports/setEnterMarks',
            editViewTermReports: 'termReports/setEditViewTermReports',
            welcomeMessage: 'termReports/setWelcomeMessage',            
        }),

        async logOut () {            
            this.setOverlay(true);
            this.mainMenu(true);
            this.enterMarks(false);
            this.editViewTermReports(false);
            this.welcomeMessage(true);
            this.setStep(1);            
            await this.signOut();
            this.setExpand(true);
            this.setOverlay(false);
            //console.log("logout complete");            
            this.$router.replace('/')
        }

        
    }
}
</script>